import React, { useEffect, useState } from 'react'
import {change, Field, formValueSelector, reduxForm, reset} from 'redux-form'
import PropTypes from 'prop-types'
import renderField from '../../../../utils/renderField'
import CustomSelect from '../../../ui/CustomSelect'
import { isVrm } from '../../../../utils/misc'
import * as validator from 'validator'
import { Link } from 'react-router-dom'
import renderDatePicker from '../../../ui/renderDatePicker'
import { connect } from 'react-redux'
import { numericality } from 'redux-form-validators'
import CustomAsyncSelect from '../../../ui/CustomAsyncSelect'
import api from '../../../../utils/api'
import pDebounce from '../../../../utils/pDebounce'
import { usePostcodeLookupWithRedux } from "../../../../hooks/usePostcodeLookupWithRedux";

const StepDetails = (props) => {
  const {
    handleSubmit,
    onSubmit,
    valid,
    handleLookup,
    lookupData,
    change,
    registerField,
    setLastStepVisited,
    resetForm,
    dispatch,
    handleRetrieveQuote,
    renderDP,
    vrm,
    manualInfo,
    setManualInfo,
    vehicleInfo,
    loadedQuote,
    ...form
  } = props
  const [vehicleLookupAlert, setVehicleLookupAlert] = useState(false)

  const fetchQuotes = async (query) => {
    try {
      let res = await Promise.all([
        api.get(`/direct-sales/prospects`, {
          params: {
            ref: query,
            limit: 10,
          },
        }),
        api.get(`/direct-sales/policies`, {
          params: {
            ref: query,
            limit: 10,
          },
        }),
      ])
      return [
        {
          label: 'Quotes',
          options: [
            ...res[0].data.data.map((quote) => ({
              retrieveType: 'quote',
              ...quote,
            })),
          ],
        },
        {
          label: 'Policies',
          options: [
            ...res[1].data.data.map((policy) => ({
              retrieveType: 'policy',
              ...policy,
            })),
          ],
        },
      ]
    } catch (error) {
      return []
    }
  }

  const loadQuoteOptions = pDebounce((val) => {
    return fetchQuotes(val)
  }, 1000)

  useEffect(() => {
    setVehicleLookupAlert(vrm && isVrm(vrm) && !lookupData)
  }, [vrm, lookupData])

  let {markup, forceManualAddress} = usePostcodeLookupWithRedux('get-quote')

  useEffect(() => {
    if (loadedQuote !== false) {
      forceManualAddress();
    }
  }, [loadedQuote])


  return (
    <div className="mw-37-5rem mx-auto mt-5 mb-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <h6 className="mt-4 mb-5 text-uppercase">
              Already received a quote?
            </h6>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              component={CustomAsyncSelect}
              label="Quote number or policy number (optional)"
              name="quote_id"
              placeholder={'Search by quote number/policy'}
              isClearable
              cacheOptions
              loadOptions={loadQuoteOptions}
              onChange={(option) => {
                if (option) {
                  handleRetrieveQuote(option.id, option.retrieveType)
                }
              }}
              defaultOptions={false}
              getOptionLabel={(option) =>
                `${option.ref} - ${option.vehicle.vrm} - ${option.policyholder.first_name} ${option.policyholder.last_name}`
              }
              getOptionValue={(option) => option.id}
            />
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col">
            <h6 className="mt-4 mb-4 text-uppercase">Policyholder details</h6>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Company name (optional)"
              component={renderField}
              name={`company_name`}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Field
              label="Title"
              component={CustomSelect}
              placeholder="Select"
              size={'md'}
              name={`title`}
              options={[
                {
                  name: 'Mr',
                  value: 'Mr',
                },
                {
                  name: 'Mrs',
                  value: 'Mrs',
                },
                {
                  name: 'Miss',
                  value: 'Miss',
                },
                {
                  name: 'Ms',
                  value: 'Ms',
                },
                {
                  name: 'Doctor',
                  value: 'Doctor',
                },
                {
                  name: 'Reverend',
                  value: 'Reverend',
                },
              ]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="First name"
              component={renderField}
              name={`first_name`}
              className="form-control"
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Last name"
              component={renderField}
              name={`last_name`}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Telephone (mobile)"
              component={renderField}
              name={`tel_mobile`}
              className="form-control"
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Telephone (home)"
              component={renderField}
              name={`tel_home`}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field
              type="email"
              label="Email"
              component={renderField}
              name={`email`}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            {markup}
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col">
            <h6 className="mt-4 mb-4 text-uppercase">Vehicle Details</h6>
          </div>
        </div>

        {!manualInfo && <>
          <div className="row">
            <div className="col">
              <Field
                type="text"
                label="Vehicle reg."
                component={renderField}
                name={`vrm`}
                className={`form-control ${vehicleLookupAlert ? 'is-invalid' : ''}`}
                button="Lookup"
                handleSubmit={handleLookup}
                normalize={value => value && value.replace(/\s/g, '').toLocaleUpperCase()}
              />
              {vehicleLookupAlert &&
                <div className="invalid-feedback" style={{display: "block", marginBottom: '1.3rem'}}>Please lookup information for {vrm}</div>
              }
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <Link
                to="#"
                className="text-blue"
                onClick={() => {
                  setManualInfo(true)
                }}
              >
                Enter Details Manually
              </Link>
            </div>
          </div>
        </>}

        {!manualInfo && lookupData && <>
          <div className="row">
            <div className="col">
              <div className={`${lookupData ? 'd-block' : 'd-none'}`}>
                <div className="mt-1 vehicle-details-lookup">
                  <table className="table table-striped table-bordered">
                    <tbody className="smaller">
                    <tr>
                      <td className="fw-600">VIN</td>
                      <td>{lookupData.VIN}</td>
                    </tr>
                    <tr>
                      <td className="fw-600">Type</td>
                      <td>{lookupData.Type}</td>
                    </tr>
                    <tr>
                      <td className="fw-600">Make</td>
                      <td>{lookupData.Make}</td>
                    </tr>
                    <tr>
                      <td className="fw-600">Model</td>
                      <td>{lookupData.Model}</td>
                    </tr>
                    <tr>
                      <td className="fw-600">Series</td>
                      <td>{lookupData.Series}</td>
                    </tr>
                    <tr>
                      <td className="fw-600">Drive Type</td>
                      <td>{lookupData.DriveType}</td>
                    </tr>
                    <tr>
                      <td className="fw-600">Engine Size (cc)</td>
                      <td>{lookupData.EngineSize}</td>
                    </tr>
                    <tr>
                      <td className="fw-600">Fuel Type</td>
                      <td>{lookupData.FuelType}</td>
                    </tr>
                    <tr>
                      <td className="fw-600">Transmission</td>
                      <td>{lookupData.Transmission}</td>
                    </tr>
                    <tr>
                      <td className="fw-600">Manufactured</td>
                      <td>{lookupData.RegDate}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>}

        {!!manualInfo && <>
          <div className="col">
            <div className="row">
              <div className="col-sm-12">
                <Field
                  type="text"
                  label="Vehicle Registration"
                  component={renderField}
                  name={`vehicle.VRM`}
                  className="form-control"
                  normalize={value => value && value.replace(/\s/g, '').toLocaleUpperCase()}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="VIN"
                  component={renderField}
                  name={`vehicle.VIN`}
                  className="form-control"
                  normalize={value => value && value.replace(/\s/g, '').toLocaleUpperCase()}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  className="select-z-index-fix2"
                  label="Type"
                  component={CustomSelect}
                  placeholder="Select"
                  name={`vehicle.Type`}
                  getOptionLabel={(option) => option.value}
                  getOptionValue={(option) => option.value}
                  options={[
                    { value: 'Car' },
                    { value: 'Bike' },
                    { value: 'Van' },
                    { value: 'Motorhome' },
                    { value: 'Other' },
                  ]}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="Make"
                  component={renderField}
                  name={`vehicle.Make`}
                  className="form-control"
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="Model"
                  component={renderField}
                  name={`vehicle.Model`}
                  className="form-control"
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="Drive Type"
                  component={renderField}
                  name={`vehicle.DriveType`}
                  className="form-control"
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="Fuel"
                  component={renderField}
                  name={`vehicle.FuelType`}
                  className="form-control"
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="Transmission"
                  component={renderField}
                  name={`vehicle.Transmission`}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <Field
                  type="text"
                  label="Engine Size (cc)"
                  component={renderField}
                  name={`vehicle.EngineSize`}
                  className="form-control"
                  validate={numericality({
                    int: true,
                    msg: 'Field must contain only numbers.',
                  })}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="text"
                  id="manufactured"
                  label="Registered"
                  component={renderDatePicker}
                  inputValueFormat="DD-MM-YYYY"
                  placeholderText="DD/MM/YYYY"
                  dateFormat="dd/MM/yyyy"
                  fixedHeight
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  yearDropdownItemNumber={3}
                  name={`vehicle.Manufactured`}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <Link
                to="#"
                className="text-blue"
                onClick={() => {
                  setManualInfo(false)
                }}
              >
                Enter Vehicle Registration
              </Link>
            </div>
          </div>
        </>}


        <div className="row">
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Current mileage"
              component={renderField}
              name={`mileage`}
              className="form-control"
              append={{
                direction: 'right',
                text: 'miles',
              }}
              validate={numericality({
                int: true,
                msg: 'Field must contain only numbers.',
              })}
            />
          </div>
          <div className="col-md-6 b-form-group">
            <Field
              type="text"
              label="Estimated mileage per year"
              component={renderField}
              name={`annual_mileage`}
              append={{
                direction: 'right',
                text: 'miles',
              }}
              validate={numericality({
                int: true,
                msg: 'Field must contain only numbers.',
              })}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Field
              type="text"
              label="Vehicle sale price"
              component={renderField}
              name={`vehicle_sale_price`}
              fieldIcon="wf-icon-gbp"
              className="form-control"
              validate={numericality({
                int: true,
                msg: 'Field must contain only numbers.',
              })}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Field
              className="select-z-index-fix"
              label="Service history"
              component={CustomSelect}
              placeholder="Select"
              name={`service_history`}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              options={[
                {
                  name: 'Full, main dealer',
                  value: 'Full, main dealer',
                },
                {
                  name: 'Full, non-main dealer',
                  value: 'Full, non-main dealer',
                },
                {
                  name: 'Partial, main dealer',
                  value: 'Partial, main dealer',
                },
                {
                  name: 'Partial, non-main dealer',
                  value: 'Partial, non-main dealer',
                },
                {
                  name: 'No service history',
                  value: 'No service history',
                },
              ]}
            />
          </div>

          <div className="col-6">
            <Field
              className="select-z-index-fix"
              label="Length of time owned"
              component={CustomSelect}
              placeholder="Select"
              name={`time_owned`}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              options={[
                {
                  name: 'Less than 1 month',
                  value: 'Less than 1 month',
                },
                {
                  name: 'Less than 2 months',
                  value: 'Less than 2 months',
                },
                {
                  name: 'Less than 6 months',
                  value: 'Less than 6 months',
                },
                {
                  name: 'More than 6 months',
                  value: 'More than 6 months',
                },
                {
                  name: 'More than 1 year',
                  value: 'More than 1 year',
                },
              ]}
            />
          </div>
        </div>

        <div className="row col">
          <p>
            As no two vehicles are used exactly the same we may be able to put a better package together for you with a personalised quote.
            We won't share your details with other companies or use them for marketing.
          </p>
          <Field
            type="checkbox"
            label="I'd like to get a personalised quote, please contact me."
            component={renderField}
            id={'custom_quote_requested'}
            name={`custom_quote_requested`}
          />
        </div>

        <hr />

        <div className="row">
          <div className="col">
            <h6 className="mt-4 mb-4 text-uppercase">Policy Details</h6>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            {renderDP && (
              <Field
                name="start_date"
                label="Policy start date"
                style={{ width: '100%' }}
                className="form-control"
                inputValueFormat="YYYY-MM-DD"
                placeholderText="DD/MM/YYYY"
                dateFormat="dd/MM/yyyy"
                fixedHeight
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={3}
                component={renderDatePicker}
              />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!valid || !vehicleInfo}
              >
                Choose a plan
              </button>
              <button
                type="button"
                className="btn btn-outline btn-form-cancel"
                onClick={() => dispatch(reset('get-quote'))}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

StepDetails.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleLookup: PropTypes.func.isRequired,
  lookupData: PropTypes.object,
  setLastStepVisited: PropTypes.func.isRequired,
  lastStepVisited: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
}

const validate = (values, form) => {
  let errors = {}

  if (!values.tel_mobile && !values.tel_home) {
    errors.tel_mobile =
      'Please provide either your mobile or home telephone number.'
  }

  if (!values.first_name)
    errors.first_name = "Please enter customer's first name"

  if (!values.last_name) errors.last_name = "Please enter customer's last name"

  if (!values.title) errors.title = 'Please select title'

  if (!values.vrm) errors.vrm = 'Vehicle registration number is required.'

  if (values.vrm && !isVrm(values.vrm)) {
    errors.vrm = 'Please enter a valid vehicle registration number.'
  }

  if (values.email && !validator.isEmail(values.email))
    errors.email = "The email address doesn't quite look right."

  if (!values.mileage)
    errors.mileage = 'Please enter current mileage of the vehicle.'

  if (!values.annual_mileage)
    errors.annual_mileage = 'Please enter the estimated annual mileage.'

  if (!values.retail_price)
    errors.retail_price = "Please enter the vehicle's estimated value."

  if (!values.start_date)
    errors.start_date = "Please enter a start date for the policy or quote."

  return errors
}

const selector = formValueSelector('get-quote');

export default connect((state) => ({
  vrm: selector(state, 'vrm'),
}), { change })(
  reduxForm({
    form: 'get-quote',
    destroyOnUnmount: false,
    validate,
  })(StepDetails)
)
