import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import renderField from '../../../../../utils/renderField'
import CustomSelect from '../../../../ui/CustomSelect'
import Button from '../../../../ui/Button'
import { usePostcodeLookupWithRedux } from '../../../../../hooks/usePostcodeLookupWithRedux'
import {isEmail} from 'validator'
import {formatDate, prettyNumber} from "../../../../../utils/misc";
import moment from "moment";
import { get } from 'lodash';
import api from '../../../../../utils/api';

const StepPolicyholder = ({
  loading,
  handleSubmit,
  onSubmit,
  valid,
  goStep,
  dealer,
  dealerId,
  vrm,
  mileage,
  coverLevel,
  duration,
  startDate,
  claimLimit,
  labourRate,
  excess,
  sundries,
  price,
  dealerDisabled,
  policyStep,
  vehicleStep,
  saveDraft,
  soldByUsers,
}) => {
  const [createPolicyConfig, setCreatePolicyConfig] = useState({})
  useEffect(() => {
    if (!dealerId && dealer) {
      dealerId = dealer.id
    }

    if (!dealerId) {
      return
    }
    
    api.get(`/external-sales/policies/create/${dealerId}/config`)
      .then(res => {setCreatePolicyConfig(res.data); console.log('config', res.data)})
      .catch(() => setCreatePolicyConfig({}))
  }, [dealer])

  const { markup } = usePostcodeLookupWithRedux('create-policy-policyholder', false, ['postcode', 'address_1', 'city'])

  const policyEndDate = () => {
    const date = moment(startDate, 'DD/MM/YYYY')
    if (date.isValid()) {
      return moment(date)
        .add(duration.value, 'month')
        .subtract(1, 'day')
        .format('DD/MM/YYYY')
    }
    return false
  }

  return (
    <div className="mx-auto" style={{ maxWidth: '900px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row justify-content-between">
          <div className="col-6">
            <div className="row">
              <div className="col">
                <h6 className="mt-4 mb-4 text-uppercase">Policyholder details</h6>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Field
                  label="Company name (optional)"
                  component={renderField}
                  name={`ph_company_name`}
                  id={'company_name'}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <Field
                  label="Title *"
                  component={CustomSelect}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.name}
                  name={`title`}
                  id={'title'}
                  options={[
                    {
                      name : 'Mr',
                      value: 1,
                    },
                    {
                      name : 'Mrs',
                      value: 2,
                    },
                    {
                      name : 'Miss',
                      value: 3,
                    },
                    {
                      name : 'Ms',
                      value: 4,
                    },
                    {
                      name : 'Doctor',
                      value: 5,
                    },
                    {
                      name : 'Reverend',
                      value: 5,
                    },
                  ]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Field
                  label="First name *"
                  component={renderField}
                  name={`ph_first_name`}
                  id={'firstName'}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col">
                <Field
                  label="Last name *"
                  component={renderField}
                  name={`ph_last_name`}
                  id={'lastName'}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            {markup}

            <div className="row">
              <div className="col-md-6 b-form-group">
                <Field
                  type="text"
                  label="Telephone (mobile) *"
                  component={renderField}
                  name={`ph_tel_mobile`}
                  className="form-control"
                />
              </div>
              <div className="col-md-6 b-form-group">
                <Field
                  type="text"
                  label="Telephone (home)"
                  component={renderField}
                  name={`ph_tel_home`}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Field
                  type="email"
                  label="Email *"
                  component={renderField}
                  name={`ph_email`}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                  <Field
                    label="Sold by"
                    component={CustomSelect}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                    name={`sold_by`}
                    id={'sold_by'}
                    options={soldByUsers}
                  />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <p>* Mandatory fields</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="form-group">
                  <Button
                    className="btn btn-secondary"
                    type="submit"
                    disabled={!valid}
                    loading={loading}
                  >
                    Send for approval
                  </Button>
                  {saveDraft &&
                  <button
                    type="button"
                    className="btn btn-outline btn-form-cancel"
                    onClick={() => saveDraft()}
                  >
                    Save for later
                  </button>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="row">
              <div className="col">
                <h6 className="mt-4 mb-4 text-uppercase">Summary</h6>
              </div>
            </div>
            {!dealerDisabled && <div
              className="bg-white shadow-light position-relative"
              style={{ padding: '30px' }}
            >
              <i
                className="wf-icon-edit position-absolute"
                style={{ top: 12, right: 12 }}
                onClick={() => goStep(1)}
                />
              <span className="label d-block">Dealership</span>
              <p className="mb-0">{get(dealer, 'name', '-')}</p>
            </div>}

            <div
              className="bg-white shadow-light position-relative mt-3"
              style={{ padding: '30px' }}
            >
              <i
                className="wf-icon-edit position-absolute"
                style={{ top: 12, right: 12 }}
                onClick={() => goStep(policyStep ? policyStep : 3)}
              />
              <span className="label d-block">Cover level</span>
              <p>{get(coverLevel, 'name', '-')}, {get(duration, 'name', '-')}</p>

              <span className="label d-block">Start date</span>
              <p>{formatDate(startDate)}</p>

              <span className="label d-block">End date</span>
              <p>{policyEndDate() ? policyEndDate() : '-'}</p>

              <span className="label d-block">Claim limit</span>
              <p>{get(claimLimit, 'name', '-')}</p>

              <span className="label d-block">Labour rate</span>
              <p>&pound;{get(labourRate, 'title', '-')}</p>

              <span className="label d-block">Excess</span>
              <p>{get(excess, 'title', '-')}%</p>

              <span className="label d-block">Sundries</span>
              <p>{(sundries && sundries.length) ? (sundries || []).map(s => s.title).join(', ') : '-'}</p>

              {createPolicyConfig.can_view_dealer_pricing &&
              <div className="d-flex justify-content-between mt-3">
                <span className="font-xl fw-800 text-gray-800">Price</span>
                <span className="font-xl fw-800 text-gray-800">
                  {price ? `£${price.toFixed(2)}` : `-`}
                </span>
              </div>
              }

            </div>

            <div
              className="bg-white shadow-light position-relative mt-3 mb-5"
              style={{ padding: '30px' }}
            >
              <i
                className="wf-icon-edit position-absolute"
                style={{ top: 12, right: 12 }}
                onClick={() => goStep(vehicleStep ? vehicleStep : 2)}
              />
              <span className="label d-block">Vehicle reg.</span>
              <p>{vrm || '-'}</p>

              <span className="label d-block">Current mileage</span>
              <p className="mb-0">{ mileage ? prettyNumber(mileage) : '-'} miles</p>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

StepPolicyholder.propTypes = {
  loading     : PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid       : PropTypes.bool.isRequired,
  goStep      : PropTypes.func.isRequired,
}

const validate = values => {
  let errors = {}

  if (!values.title) {
    errors.title = 'Please select a title.'
  }

  if (!values.ph_first_name) errors.ph_first_name = 'Please enter a first name.'
  if (!values.ph_last_name) errors.ph_last_name = 'Please enter a last name.'
  if (!values.ph_postcode) errors.ph_postcode = 'Please enter a postcode.'
  if (!values.ph_address) errors.ph_address = 'Please select an address.'
  if (!values.ph_tel_mobile)
    errors.ph_tel_mobile = 'Please enter a telephone number.'
  if (!values.ph_email) errors.ph_email = 'Please enter an email address.'

  if (values.ph_email && !isEmail(values.ph_email)) {
    errors.ph_email = 'The email address doesn\'t quite look right.'
  }

  return errors
}

const dealerSelector = formValueSelector('create-policy-dealer')
const vehicleSelector = formValueSelector('create-policy-vehicle')
const policySelector = formValueSelector('create-policy-policy')

export default connect((state) => ({
  dealer: dealerSelector(state, 'dealer'),
  vrm: vehicleSelector(state, 'vrm'),
  mileage: vehicleSelector(state, 'current_mileage'),
  coverLevel: policySelector(state, 'cover_level'),
  duration: policySelector(state, 'duration'),
  startDate: policySelector(state, 'policy_start_date'),
  claimLimit: policySelector(state, 'claim_limit'),
  labourRate: policySelector(state, 'labour_rate'),
  excess: policySelector(state, 'excess'),
  sundries: policySelector(state, 'sundries'),
}))(
  reduxForm({
    form            : 'create-policy-policyholder',
    destroyOnUnmount: false,
    validate,
  })(StepPolicyholder),
)
