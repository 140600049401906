import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import StepVehicle from "../../../ExternalSales/Policies/Create/StepVehicle";
import api from "../../../../../utils/api";
import {API_URL} from "../../../../../utils/constants";
import {get} from "lodash";
import {NotificationManager} from "react-notifications";
import moment from "moment";
import {connect} from "react-redux";
import {getFormValues, reduxForm, reset} from "redux-form";
import StepPolicy from "../../../ExternalSales/Policies/Create/StepPolicy";
import StepPolicyholder from "../../../ExternalSales/Policies/Create/StepPolicyholder";
import {history} from "../../../../../utils/history";

const CreatePolicy = ({vehicle, policy, policyholder, dispatch}) => {
  const [step, setStep] = useState(1)
  const [lastStepVisited, setLastStepVisited] = useState(1)
  const [steps, setSteps] = useState(formSteps)
  const [loading, setLoading] = useState(false)

  const nextStep = () => {
    setStep(step + 1)
  }
  useEffect(() => {
    setSteps(steps => [
      ...steps.map(item => {
        if (item.id === step) {
          item.current = true
        } else {
          item.current = false
        }
        return item
      }),
    ])
  }, [step])
  /**
   * Only allow the user to navigate
   * to the last step he visited.
   *
   * @param {Number} step
   */

  const handleGoBackTo = step => {
    if (lastStepVisited >= step.id) {
      setStep(step.id)
    }
  }

  const [dealer, setDealer] = useState(null)
  useEffect(() => {
    api.get('/dealer-portal/account-details')
      .then(res => {
        setDealer(res.data)
      })
  }, [])

  /**
   * Vehicle Information State and Functions
   */
  /**
   * Perform vehicle registration number lookup
   *
   * @param {String} vrm
   */
  const [lookupData, setLookupData] = useState(null)
  const [useManualInfo, setUseManualInfo] = useState(false)
  const [vehicleInfo, setVehicleInfo] = useState(null)
  useEffect(() => {
    //If Manual Data entry, set vehicle info, else use lookupdata
    if (useManualInfo) {
      let vehicleInfo = get(vehicle, 'vehicle', {});
      if (vehicleInfo.Manufactured) {
        vehicleInfo.RegDate = moment(vehicleInfo.Manufactured).format('DD-MM-YYYY');
      }
      setVehicleInfo(vehicleInfo);
    } else {
      setVehicleInfo(lookupData);
    }
  }, [lookupData, useManualInfo, vehicle])

  const handleLookup = vrm => {
    if (!vrm) return false
    api
      .get(`${API_URL}/misc/vrm-lookup?vrm=${vrm}`)
      .then(res => {
        if (get(res, 'data.Model', false) === false) {
          setLookupData(null);
          setUseManualInfo(true);
          NotificationManager.error('Oops! We could not find information for this registration. Please enter the details manually.');
        } else {
          setLookupData(res.data)
        }
      })
      .catch(() => {
        NotificationManager.error(
          'Oops! Something went wrong. Please try again.',
        )
      })
  }

  /**
   * Policy Pricing Functions
   */
  const [price, setPrice] = useState(null)
  useEffect(() => {
    if (
      vehicleInfo && get(vehicle, 'current_mileage', false) && get(vehicle, 'vehicle_sale_price', false) && //Vehicle Info is set
      dealer && //Dealer Info is set
      get(policy, 'cover_level.id', false) && get(policy, 'duration.name', false) && get(policy, 'claim_limit.value', false) //Policy Info is set
    ) {
      api.get(`/external-sales/policies/create/${dealer.id}/price`, {
        params: {
          legacy: false,
          type: vehicleInfo.Type.value || vehicleInfo.Type,
          make: vehicleInfo.Make,
          model: vehicleInfo.Model,
          drive_type: vehicleInfo.DriveType,
          engine_size: vehicleInfo.EngineSize,
          reg_date: vehicleInfo.RegDate.split('-').reverse().join('-'),
          manual_data: useManualInfo,
          mileage: vehicle.current_mileage,
          sale_price: vehicle.vehicle_sale_price,
          cover_level_id: policy.cover_level.id,
          duration: policy.duration.name,
          claim_limit: policy.claim_limit.value,
          excess: get(policy, 'excess.title', null),
          labour_rate: get(policy, 'labour_rate.title', null),
          sundries: get(policy, 'sundries', []).map(sundry => sundry.title),
        }
      })
        .then(res => {
          setPrice(res.data.price)
        })
    } else {
      setPrice(null);
    }
  }, [dealer, vehicle, vehicleInfo, policy])

  /**
   * Form Submission Functions
   */

  const getDataForApi = () => ({
    dealer_id: dealer.id,
    cover_level_id: policy.cover_level.id,
    duration: policy.duration.name, //This one requires the month suffix, so use the human name attribute
    claim_limit: policy.claim_limit.value,
    labour_rate: get(policy, 'labour_rate.title', null),
    start_date: moment(policy.policy_start_date).format('YYYY-MM-DD'),
    excess: get(policy, 'excess.title', null),
    excess_type: 'Percentage',
    sundries: get(policy, 'sundries', []).map(sundry => sundry.title),
    net: get(policy, 'net', 0),
    vat: get(policy, 'vat', 0),

    vrm: vehicle.vrm,
    vin: vehicleInfo.VIN,
    type: vehicleInfo.Type.value || vehicleInfo.Type,
    make: vehicleInfo.Make,
    model: vehicleInfo.Model,
    drive_type: vehicleInfo.DriveType,
    engine_size: vehicleInfo.EngineSize,
    transmission: vehicleInfo.Transmission,
    fuel_type: vehicleInfo.FuelType,
    reg_date: vehicleInfo.RegDate.split('-').reverse().join('-'),
    manual_data: useManualInfo,
    mileage: vehicle.current_mileage,
    retail_price: vehicle.vehicle_sale_price,

    company_name: policyholder.ph_company_name,
    title: policyholder.title.name,
    first_name: policyholder.ph_first_name,
    last_name: policyholder.ph_last_name,
    address_1: get(policyholder, 'address_1', get(policyholder, 'address.address_1', undefined)),
    address_2: get(policyholder, 'address_2', get(policyholder, 'address.address_2', undefined)),
    address_3: get(policyholder, 'address_3', get(policyholder, 'address.address_3', undefined)),
    city: get(policyholder, 'city', get(policyholder, 'address.city', undefined)),
    county: get(policyholder, 'county', get(policyholder, 'address.county', undefined)),
    postcode: get(policyholder, 'postcode', get(policyholder, 'address.postcode', undefined)),
    tel1: policyholder.ph_tel_mobile,
    tel2: policyholder.ph_tel_home,
    email: policyholder.ph_email,

    sold_by_id: policyholder.sold_by ? policyholder.sold_by.id : null,
  })

  const handleSubmit = () => {
    if (loading) return;

    setLoading(true);
    api.post('/dealer-portal/policies', {...getDataForApi(), action: 'send_for_approval'})
      .then((res) => {
        NotificationManager.success('Woohoo! You created a policy.')
        //Reset Each Form
        //When you reset these forms, all the props disappear, so you must set defaults in the form thats live when you submit.
        //Destroy on unmount is set to false to ensure we keep the data in the other steps.
        dispatch(reset('create-policy-policy'))
        dispatch(reset('create-policy-policyholder'))
        dispatch(reset('create-policy-vehicle'))
        history.push('/dealer-portal/policies')
      })
      .catch(err => {
        if (err && err.response.status === 422) {
          const errors = err.response.data.errors
          for (const field of Object.keys(errors)) {
            NotificationManager.error(errors[field][0])
          }
        } else {
          NotificationManager.error('Oops! There was a problem creating this policy.');
        }
      })
      .finally(() => setLoading(false));
  }

  const handleSaveDraft = () => {
    api.post('/dealer-portal/policies', {...getDataForApi(), action: 'save_for_later'})
      .then((res) => {
        NotificationManager.success('Woohoo! You created a policy.')
        //Reset Each Form
        //When you reset these forms, all the props disappear, so you must set defaults in the form thats live when you submit.
        //Destroy on unmount is set to false to ensure we keep the data in the other steps.
        dispatch(reset('create-policy-policy'))
        dispatch(reset('create-policy-policyholder'))
        dispatch(reset('create-policy-vehicle'))
        history.push('/dealer-portal/policies')
      })
      .catch(err => {
        if (err && err.response.status === 422) {
          const errors = err.response.data.errors
          for (const field of Object.keys(errors)) {
            NotificationManager.error(errors[field][0])
          }
        } else {
          NotificationManager.error('Oops! There was a problem saving this policy.');
        }
      });
  }


  return <>
    {dealer && <div>
    <ol className="breadcrumb">
      <li className="breadcrumb-item">
        <Link to="/dealer-portal/policies" className="open active">
          Policies
        </Link>
      </li>
      <li className="breadcrumb-item">
        <span className="active">Add policy</span>
      </li>
    </ol>
    <h2 className="text-center mt-5 mb-5">Add a policy</h2>
    {steps && (
      <div className="px-5 bg-grey text-text-center d-flex justify-content-center py-3 form-steps">
        {steps.map((step, i) => (
          <h5
            onClick={() => handleGoBackTo(step)}
            className={`form-step fw-800 text-capitalize
        ${step.current ? 'text-primary current' : ''}
        `}
            key={i}
          >
            {step.id}. {step.title}
          </h5>
        ))}
      </div>
    )}
    <div className="container-fluid mt-5">
      {step === 1 && (
        <StepVehicle
          handleLookup={handleLookup}
          lookupData={lookupData}
          setLookupData={setLookupData}
          manualInfo={useManualInfo}
          setManualInfo={setUseManualInfo}
          onSubmit={() => {
            nextStep()
            setLastStepVisited(1)
          }}
          vehicleInfo={vehicleInfo}
        />
      )}
      {step === 2 &&
      <StepPolicy
        onSubmit={() => {
          nextStep()
          setLastStepVisited(2)
        }}
        vehicleData={vehicleInfo}
        price={price}
        dealerId={dealer.id}
        policyCostText={`This is the cost Warranty First will charge ${dealer.name} (exc. Tax)`}
        policyPriceText='Enter the price the dealership has charged the policyholder for the policy. (inc. Tax)'
      />}
      {step === 3 && (
        <StepPolicyholder
          price={price}
          onSubmit={handleSubmit}
          loading={loading}
          goStep={setStep}
          dealerDisabled={true}
          policyStep={2}
          vehicleStep={1}
          saveDraft={handleSaveDraft}
          soldByUsers={dealer.contacts}
          dealerId={dealer.id}
        />
      )}
    </div>
  </div>}
  </>
}

const formSteps = [
  {
    id: 1,
    title: 'Vehicle',
    current: false,
  },
  {
    id: 2,
    title: 'Policy',
    current: false,
  },

  {
    id: 3,
    title: 'Policyholder',
    current: false,
  },
]

export default connect((state) => ({
  vehicle: getFormValues('create-policy-vehicle')(state),
  policy: getFormValues('create-policy-policy')(state),
  policyholder: getFormValues('create-policy-policyholder')(state)
}))(
  reduxForm({
    form: 'create-policy',
    destroyOnUnmount: false,
  })(CreatePolicy),
)