import React from 'react'
import propTypes from 'prop-types'

const Button = ({
  children,
  name,
  type,
  className,
  disabled,
  loading,
  onClick
}) => {
  return (
    <button
      name={name}
      type={type}
      className={className}
      disabled={disabled || loading}
      style={loading ? { display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px' } : {}}
      onClick={onClick}
    >
      {loading && <i className="fa fa-spinner fa-lg fa-spin" />}
      {children}
    </button>
  )
}

Button.propTypes = {
  children: propTypes.node,
  name: propTypes.string,
  type: propTypes.string,
  className: propTypes.string,
  disabled: propTypes.bool,
  loading: propTypes.bool,
  onClick: propTypes.func,
}

export default Button
