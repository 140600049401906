import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Avatar from '../../../../ui/Avatar'
import { echo, formatDate, formatExcess, money } from '../../../../../utils/misc'
import {API_URL} from "../../../../../utils/constants";
import SlideToggle from "../../../../ui/SlideToggle";
import PolicyDetailsEdit from "./PolicyDetailsEdit";
import moment from "moment";
import api from '../../../../../utils/api'
import { hasPermission } from '../../../../../utils/auth';
import {NotificationManager} from 'react-notifications'

export const PolicyDetails = ({ policy, isQuote, refreshPolicy }) => {
  const { cover_level, sold_by, created_by } = policy

  const [policyEdit, setPolicyEdit] = useState(false);

  const downloadUrl = () => {
    return API_URL + `/downloads/pdf/external-sales/policies/${policy.id}/certificate?token=` + JSON.parse(localStorage.getItem('wf-token'))
  }

  const downloadSummaryUrl = () => {
    return API_URL + `/downloads/pdf/external-sales/policies/${policy.id}/summary?token=` + JSON.parse(localStorage.getItem('wf-token'))
  }

  const handlePolicyEditSubmit = (values) => {

    let url;
    let data;

    console.log(policy)
    if (policy.status === 'Draft') {
      url = `/dealer-portal/policies/${policy.id}`
      data = {
        dealer_id: policy.dealer.id,
        company_name: values.company_name,
        cover_level_id: values.cover_level.id,
        duration: values.duration.name,
        claim_limit: values.claim_limit.value,
        labour_rate: values.labour_rate.title,
        start_date: moment(values.start_date).format('YYYY-MM-DD'),
        excess: values.excess.title,
        excess_type: 'Percentage',
        sundries: values.sundries.map(s => s.title),
        net: values.policy_price / 1.2,
        vat: (values.policy_price / 1.2) * 0.2,

        ...policy.vehicle,
        ...policy.policyholder,

        sold_by_id: values.sold_by ? values.sold_by.id : null,

        action: 'save_for_later',
      }
    } else {
      url = `/dealer-portal/policies/${policy.id}/update-non-draft`
      data = {
        sold_by_id: values.sold_by ? values.sold_by.id : null,
      }
    }

    api.patch(url, data)
      .then(res => {
        NotificationManager.success('Woohoo! The policy has been updated');
        setPolicyEdit(false);
        refreshPolicy();
      })
      .catch(err => {
        if (err && err.response.status === 422) {
          const errors = err.response.data.errors
          for (const field of Object.keys(errors)) {
            NotificationManager.error(errors[field][0])
          }
        } else {
          NotificationManager.error('Oops! There was a problem updating the policy information.');
        }
      });
  }

  return (
    policy &&
    <div className="px-5">
      {!policyEdit &&
      <>
        <div className="row mt-4">
          <div className="col d-flex align-items-center">
            <h6 className="mt-3 mb-4 text-uppercase">
              {isQuote ? 'Quote' : 'Policy'} details
            </h6>
          </div>
          <div className="col d-flex align-items-center justify-content-end">
          {(policy.status === 'Draft' || hasPermission('dealer_portal.policies.set_sold_by')) &&
            <button
              className="btn btn-light btn-sm"
              onClick={() => setPolicyEdit(true)}
              style={{ width: 34, height: 34 }}
            >
              <i className="wf-icon-edit mr-0" />
            </button>
          }
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span className="label">Company</span>
            <p className="font-md">{echo(policy.company_name)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span className="label">Cover level</span>
            {cover_level && <p className="font-md">{echo(cover_level.name)}, {echo(policy.duration)}</p>}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span className="label">Start date</span>
            <p className="font-md">{formatDate(policy.start_date)}</p>
          </div>
          <div className="col">
            <span className="label">End date</span>
            <p className="font-md">{formatDate(policy.end_date)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span className="label">Claim limit</span>
            <p className="font-md">{money.format(policy.claim_limit)}</p>
          </div>
          <div className="col">
            <span className="label">Labour rate</span>
            <p className="font-md">{money.format(policy.labour_rate)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span className="label">Excess</span>
            {formatExcess(policy.excess_type, policy.excess)}
          </div>
          <div className="col">
            <span className="label">Payment type</span>
            <p className="font-md">{policy.pay_monthly ? 'Paid monthly' : 'One time payment'}</p>
          </div>
        </div>
        
        { (policy.can_view_dealer_pricing) &&
        <div className="d-flex justify-content-between mt-3 pb-4 border-bottom">
          <span className="font-xl fw-800 text-gray-800">Dealer Price<br></br>(exc. Tax)</span>
          <span className="font-xl fw-800 text-gray-800">{money.format(policy.dealer_net_override || policy.dealer_net)}</span>
        </div>
        }

        {(policy.dealer_net || policy.dealer_vat) &&
        <div className="d-flex justify-content-between mt-3 mb-5 pb-4 border-bottom">
          <span className="label font-md">Sold for (inc. Tax)</span>
          <span className="label font-md">{policy.net ? money.format(policy.net + policy.vat) : '-'}</span>
        </div>
        }

        {sold_by &&
        <div>
          <h6 className="mt-3 text-uppercase">{isQuote ? 'Quote' : 'Policy'} sold by</h6>
          <div className="d-flex py-3 mb-3">
            <>
              <Avatar user={sold_by}/>
              <span className="ml-2">{echo(sold_by.first_name)} {echo(sold_by.last_name, '')}</span>
            </>
          </div>
        </div>
        }

        {created_by &&
        <div>
          <h6 className="mt-3 text-uppercase">{isQuote ? 'Quote' : 'Policy'} created by</h6>
          <div className="d-flex py-3 mb-3">
            <>
              <Avatar user={created_by}/>
              <span className="ml-2">{echo(created_by.first_name)} {echo(created_by.last_name, '')}</span>
            </>
          </div>
        </div>
        }

        {sold_by &&
        <div>
          <h6 className="mt-3 text-uppercase">{isQuote ? 'Quote' : 'Policy'} sold by</h6>
          <div className="d-flex py-3 mb-3">
            <>
              <Avatar user={sold_by}/>
              <span className="ml-2">{echo(sold_by.first_name)} {echo(sold_by.last_name, '')}</span>
            </>
          </div>
        </div>
        }

        {policy.extras && policy.extras.length > 0 &&
        <>
          <h6 className="mt-4 text-uppercase">Policy Extras</h6>
          <div>
            {policy.extras.map((extra, idx) => (
                <p key={idx}>{extra.title}</p>
            ))}
          </div>
        </>
        }

        {['Approved', 'Cancelled', 'Expired'].indexOf(policy.status) > -1 &&
        <>
          <h6 className="mt-5 mb-4 text-uppercase">
            Policy documents
          </h6>
          {(['Approved', 'Cancelled', 'Expired'].indexOf(policy.status) > -1) &&
          <div
            className="ph-claim d-flex justify-content-between align-items-center cursor-pointer"
            onClick={() => {window.open(downloadUrl())}}
          >
            <div className="ph-claim-meta">
              <div className="ph-document-date font-sm font-weight-medium">
                {formatDate(policy.created_at)}
              </div>
              <div className="ph-document-ref font-md">
                Warranty Certificate
              </div>
            </div>
            <div className="ph-documents-links">
              <i className="wf-icon-download d-inline-block text-blue font-weight-bold"/>
            </div>
          </div>
          }
        </>
        }

      </>
      }
      <SlideToggle isVisible={policyEdit}>
        <PolicyDetailsEdit
          policy={policy}
          onCancel={() => {
            setPolicyEdit(false)
          }}
          initialValues={{
            cover_level: {name: cover_level.name, id: cover_level.id},
            start_date: moment(policy.start_date).toDate(),
            duration: {name: policy.duration, value: policy.duration.split(' ')[0]},
            claim_limit: {name: policy.claim_limit, value: policy.claim_limit},
            labour_rate: {title: policy.labour_rate},
            excess: {title: policy.excess},
            sundries: policy.extras,
            policy_price: (policy.net + policy.vat).toFixed(2),
            pay_monthly: (policy.pay_monthly) ? {name: 'Pay Monthly', value: true} : {name: 'One-time payment', value: false},
            sold_by: policy.sold_by,
          }}
          onSubmit={handlePolicyEditSubmit}
        />
      </SlideToggle>
    </div>
  )
}

PolicyDetails.defaultProps = {
  isQuote: false,
}

PolicyDetails.propTypes = {
  policy: PropTypes.object.isRequired,
}

export default PolicyDetails